import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../components/Typography.js';
import TextField from '../components/TextField.js';
import Snackbar from '../components/Snackbar.js';
import Button from '../components/Button.js';

import ImageBottom from '../images/promotionsImages/BoxingFlyer.jpg';

function BottomVideo() {

  const [gridSizeXS, setGridSizeXS] = useState(false);

  useEffect(() => {
    
    // Define your custom breakpoints here
    if (window.innerWidth < 769) {
      setGridSizeXS(true)
    } 

  }, []);

  return (
    <Box
    component="section"
    sx={{
      display: 'flex',
      overflow: 'hidden',
      position: 'relative',
      bgcolor: '#171717',
    }}
  >
    <Container component="section" sx={{ mt: gridSizeXS ? 2 : 5, mb: 5, bgcolor: '#171717' }}>
      <Grid container>
        <Grid item xs={12} md={12} sx={{ position: 'relative' }}>
          <Typography variant="h2" gutter align="center" sx={{ color: 'white', mb: 2, fontSize: { xs:'2rem' , sm: '3rem', md: '4rem', lg: '5rem'} }} >
          Upcoming Events
          </Typography>
          
        </Grid>
        <Grid
            item
            xs={12}
            md={12}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
            }}
          >
            <Box
              component="img"
              src={ImageBottom}
              sx={{
                width: '50%',
                height: '100%',
                objectFit: 'cover',
              }}
            />
          </Grid>

       
      </Grid>
    </Container>
    </Box>
  );
}

export default BottomVideo;
