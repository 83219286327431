import * as React from 'react';
import withRoot from './withRoot';

import ProductHero from './PromotionsHero';
import PhotoTextLeft from './PhotoTextLeft';
import PhotoTextRight from './PhotoTextRight';
import BottomVideo from './BottomVideo';

function Index() {
  return (
    <React.Fragment>
      <ProductHero/>
      <PhotoTextLeft/>
      <PhotoTextRight/>
      <BottomVideo/>
    </React.Fragment>
  );
}

export default withRoot(Index);
