import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../components/Typography.js';
import Button from '../components/Button.js';

import LeftPhoto from '../images/promotionsImages/SKYY MYLES.png'

function PhotoTextLeft() {
  const [gridSizeXS, setGridSizeXS] = useState(false);

  useEffect(() => {
    
    // Define your custom breakpoints here
    if (window.innerWidth < 769) {
      setGridSizeXS(true)
    } 

  }, []);

  return (
    <Box
      sx={{ flexGrow: 1, overflow: 'hidden', bgcolor: 'rgba(23, 23, 23, 1)', py: 5, px: 5 }}
    >
      <Grid container spacing={2} columns={16}>

        {/* Left-side Container */}
        <Grid item xs={16} md={8} sx={{ position: 'relative' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              //justifyContent: 'center',
              //padding: '2rem',
              //position: 'absolute',
              //alignItems: 'center', // Center text horizontally
              //top: '50%', // Adjust vertical alignment
              //transform: 'translateY(-50%)', // Adjust vertical alignment
              zIndex: 1,
              
            }}
          >
            <Typography variant="h4"  gutterBottom 
              sx={{ 
                borderBottom: '2px solid white', 
                color: 'white', 
                fontSize: { xs:'2.5rem' , sm: '3rem', md: '2rem', lg: '3rem'},
                textAlign: { xs: "center", md: "left" },
              }} >
            <span style={{ fontWeight: 'bold' }}>Skyy Myles Promotions</span>
            </Typography>
            <Typography variant="h5" sx={{ mb: 4, color: 'white', lineHeight: '1.7', textAlign: { xs: "center", md: "left" },  }} >
            Skyy Myles Promotions is a subsidiary of Skyy Myles LLC which promotes combat sports and entertainment in the Washington D.C. & Baltimore, Maryland Area.
            </Typography>

            <Typography variant="h4"  gutterBottom sx={{ color: 'white', textAlign: { xs: "center", md: "left" },  }} >
            <span style={{ fontWeight: 'bold' }}>Focuses & Services:</span>
            </Typography>
            <Typography variant="h5" align="left" sx={{ my: 1, color: 'white', lineHeight: '1.7',   }} >
              <ul>
                <li>Boxing Promotion</li>
                <li>Promotions & Event Management</li>
                  <ul>
                    <li>Organizing and coordinating live combat sport matches & events</li>
                    <li>Managing press releases and outreach</li>
                    <li>Arrange interviews and media content creation</li>
                  </ul>
                <li>Marketing & Advertisement</li>
                  <ul>
                    <li>Developing and executing marketing strategies</li>
                    <li>Utilizing social media platforms for targeted promotional campaigns</li>
                    <li>Create promotional materials such as posters, flyers, and digital content</li>
                  </ul>
                <li>Music Artist & Song Promotions</li>
              </ul>
            </Typography>

            
          </Box>
        </Grid>

        {/* Right Side */}
        <Grid item xs={16} md={8}>
            <Box
                component="img"
                src={LeftPhoto}
                sx={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                }}
              />
          </Grid>
      </Grid>
    </Box>
  );
}

export default PhotoTextLeft;
