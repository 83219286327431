import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import { onAuthStateChanged } from "firebase/auth";
import { getAuth } from 'firebase/auth';
import { auth } from './firebase';


import Home from './homePageElements/Home.js';

import AppAppBar from './AppAppBar.js';
import Footer from './AppFooter.js';
import Newsletter from './homePageElements/Newsletter.js';
import AboutHome from './aboutPageElements/AboutHome.js';
import SeminarHome from './seminarPageElements/SeminarHome.js';
import MentorHome from './mentorPageElements/MentorHome.js';
import PodcastHome from './podcastPageElements/PodcastHome.js';
import PropertiesHome from './propertiesPageElements/PropertiesHome.js';
import PropertyDetail from './propertiesPageElements/PropertyDetails.js';
import DumpHome from './dumpPageElements/DumpHome.js';
import ConstructHome from './constructionPageElements/ConstructHome.js';
import ApplicationHome from './applicationPageElements/ApplicationHome.js';
import DevelopmentHome from './developmentPageElements/DevelopmentHome.js';
import PropertyDetailDevelop from './developmentPageElements/PropertyDetails.js';
import SoldHome from './soldPageElements/SoldHome.js';
import Admin from './admin/adminMain.jsx';
import AddPropertyPage from './admin/pages/AddProperty';
import UpdatePropertyPage from './admin/pages/UpdateProperty.jsx';
import LoginPage from './admin/pages/login';
import UserPage from './admin/pages/user';
import LoanHome from './loanPageElements/LoanHome';
import LoanFormPage from './loanPageElements/LoanFormHome';
import PromotionHome from './promotionPageElements/PromotionHome.js';
import PrivacyPolicyHome from './privacyPolicyPageElements/PrivacyPolicyHome.js';
import TermsConditionsHome from './termsPageElements/TermsConditionsHome.js';

function App() {
  

  useEffect(() => {
    document.title = 'Skyy Myles LLC'; // Set your desired title here

    onAuthStateChanged(auth, (user) => {
      /*if (user) {
        console.log("User Logged In");
      } else {
        // User is signed out
        console.log("user is logged out")
      }*/
    });
    
  }, []);


  return (
    
    <Router>
      <AppAppBar />
      <Routes>
        {/* Define your routes */}
        <Route path="" element={<Home />} />
        <Route path="/" element={<Home />} />
        <Route path="/aboutus" element={<AboutHome />} />
        <Route path="/current-listings" element={<PropertiesHome />} />
        <Route path="/sold-properties" element={<SoldHome />} />
        <Route path="/seminars" element={<SeminarHome />} />
        <Route path="/mentor-program" element={<MentorHome />} />
        <Route path="/podcast" element={<PodcastHome />} />
        <Route path="/resources" element={<Home />} />
        <Route path="/properties/:address" element={<PropertyDetail />} />
        <Route path="/trash-removal-service" element={<DumpHome />} />
        <Route path="/thomas-construction-group" element={<ConstructHome />} />
        <Route path="/rental-application" element={<ApplicationHome />} />
        <Route path="/developments" element={<DevelopmentHome />} />
        <Route path="/developments/pimlico-and-wylie" element={<PropertyDetailDevelop />} />
        <Route path="/hard-money-loans" element={<LoanHome />} />
        <Route path="/loan-application" element={<LoanFormPage />} />
        <Route path="/promotions" element={<PromotionHome />} />
        
        <Route path="/admin/login" element={<LoginPage/>} />
        <Route path="/admin/*" element={<AddPropertyPage />} />
        <Route path="/admin/add-property" element={<AddPropertyPage />} />
        <Route path="/admin/update-property" element={<UpdatePropertyPage />} />
        
        <Route path="/privacy-policy" element={<PrivacyPolicyHome />} />
        <Route path="/terms-and-conditions" element={<TermsConditionsHome />} />

      </Routes>
      {/*<Newsletter/>*/}
      <Footer/>
    </Router>
  );
}

export default App;